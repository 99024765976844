import * as types from './types.js';

export type MessagesState = {
  contentMap: ContentMap,
}

type ContentMap = {[id: string]: string};

const INITIAL_STATE: MessagesState = {
  contentMap: {},
}

const reducer = (state = { ...INITIAL_STATE }, { type, payload }: any) => {
  switch (type) {
    case types.UPDATE_MESSAGE_CONTENT: {
      const { messageId, content } = payload;
      const currentContent = state.contentMap[messageId] || '';
      return {
        ...state,
        contentMap: {
          ...state.contentMap,
          [messageId]: currentContent + content,
        }
      }
    }
    default:
      return state;
  }
};


export default reducer;
