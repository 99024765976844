import { ComponentProps } from "@hubblai/hubbl-ui/components/types.js";
import { MessageButton, MessageGroupType } from "../types";
import { UISettings } from "@hubblai/hubbl-core/models/Chat.js";

type Props = {
  group: MessageGroupType,
  settings: UISettings,
  buttons?: MessageButton[],
} & ComponentProps;

const MessageGroupHeader: React.FC<Props> = ({ group, settings }) => {
  const showAvatar = (group.sender === 'current' && settings.show_current_user_avatar)
    || (group.sender !== 'current' && settings.show_other_user_avatar);
  const showName = (group.sender === 'current' && settings.show_current_user_name)
    || (group.sender !== 'current' && settings.show_other_user_name);

  return (
    <div className="hbl-group-header">
      <div className="hbl-group-header-title">
        {showAvatar && group.icon && <img src={group.icon} alt={group.name} className="hbl-group-header-img" />}
        {showName && <span className="hbl-group-header-name">{group.name}</span>}
        {settings.show_typing && group.isTyping && <span className="hbl-group-header-typing">&nbsp;is typing...</span>}
      </div>
    </div>
  )
}

export default MessageGroupHeader;
