import { Message } from "~/store/models";

type Props = {
  message: Message,
}

const MessageSystem: React.FC<Props> = ({ message }) => {
  const content = message.getContent<string>();
  return (
    <>
      {content}
    </>
  )
}

export default MessageSystem;
