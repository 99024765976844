import { Message } from "~/store/models";

type Props = {
  message: Message,
}

type MessageImageContent = {
  width: number,
  height: number,
  url: string,
  title: string,
}

const MessageImage: React.FC<Props> = ({ message }) => {
  const content = message.getContent<MessageImageContent>();
  return (
    <img width={content.width} height={content.height} src={content.url} title={content.title} />
  )
}
export default MessageImage;
