export type UIComponent = {
  type: 'button' | 'select' | 'richlistitem' | 'iframe',
}

export type UIComponentRichListItem = {
  id: string,
  title: string,
  subtitle?: string,
  description?: string,
  icon?: string,
} & UIComponent;

export type UIComponentButton = {
  text: string,
} & UIComponent;

export type UIComponentIFrame = {
  url: string,
  width?: string,
  height?: string,
} & UIComponent;

export type UIComponentSelectOption = {
  text: string,
  value?: string,
}

export type UIComponentSelect = {
  placeholder: string,
  options: UIComponentSelectOption[],
} & UIComponent;


export const isButton = (component: UIComponent): component is UIComponentButton => {
  return component.type === 'button';
}

export const isSelect = (component: UIComponent): component is UIComponentSelect => {
  return component.type === 'select';
}

export const isRichListItem = (component: UIComponent): component is UIComponentRichListItem => {
  return component.type === 'richlistitem';
}

export const isIFrame = (component: UIComponent): component is UIComponentIFrame => {
  return component.type === 'iframe';
}
