import { User } from "@hubblai/hubbl-ui/store/models.js"
import { Agent, Message } from "~/store/models"
import MessageForm from "./components/MessageForm"
import MessageList from "./components/MessageList"
import { MessageButton, MessageGroupType, MessageSender } from "./types"
import { useCallback, useMemo, useState } from "react"
import { MESSAGE_STATUS } from "@hubblai/hubbl-core/models/Message.js"
import { UISettings } from "@hubblai/hubbl-core/models/Chat.js"
import { UIComponent } from "./components/UI/types"

type Props = {
  messages: Message[],
  users: User[],
  agents: Agent[],
  isFetching: boolean,
  currentUserId: string,
  settings: UISettings,
  messageButtons?: MessageButton[],
  onSubmitMessage: (message: string) => void,
  onUserScrollToTop: () => void,
  isSubmitting: boolean,
  onComponentActivated: (messageId: string, component: UIComponent, text?: string, value?: string) => void,
}

const groupMessages = (messages: Message[], currentUserId: string): MessageGroupType[] => {
  const groups: MessageGroupType[] = [];
  let groupIndex = -1;

  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];
    const groupId = message.getGroupId();
    if (i === 0 || messages[i - 1].getGroupId() !== groupId) {
      groupIndex++;
      let sender: MessageSender = 'user';
      if (message.isByAgent()) {
        sender = 'agent';
      } else if (message.isByUser(currentUserId)) {
        sender = 'current';
      }

      groups.push({
        id: `${groupId}-${groupIndex}`,
        icon: message.getAuthorIcon(),
        name: message.getGroupName(),
        tag: message.getAuthorTag(),
        sender,
        messages: [message],
        isTyping: message.status === MESSAGE_STATUS.TYPING,
      });
    } else {
      groups[groupIndex].isTyping = groups[groupIndex].isTyping || message.status === MESSAGE_STATUS.TYPING;
      groups[groupIndex].messages.push(message);
    }
  }
  return groups;
}

const Chat: React.FC<Props> = ({ messages, users, currentUserId, agents, isFetching, onUserScrollToTop, messageButtons, settings, isSubmitting, onSubmitMessage, onComponentActivated }) => {
  const messageGroups = useMemo(() => groupMessages(messages, currentUserId), [messages, currentUserId]);
  const [formHeight, setFormHeight] = useState(0);

  const onFormSizeChanged = useCallback((height: number) => {
    setFormHeight(height);
  }, [setFormHeight]);

  return (
    <div className="hbl-chat">
      <MessageList groups={messageGroups} settings={settings} onUserScrollToTop={onUserScrollToTop} isFetching={isFetching} messageButtons={messageButtons} formHeight={formHeight} onComponentActivated={onComponentActivated} />
      <MessageForm settings={settings} currentUserId={currentUserId} onSizeChanged={onFormSizeChanged} users={users} agents={agents} onClickSubmit={onSubmitMessage} isSubmitting={isSubmitting} />
    </div>
  )
}

export default Chat;
