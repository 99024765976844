import { replaceWith } from '@hubblai/hubbl-core/lib/string.js';
import { MessageTag, parseTagsFromString, renderTags, findIncompleteTag, getMarkdownTag } from '@hubblai/hubbl-core/lib/tags.js';
import TagLink from './components/Message/TagLink';
import { UISettings } from '@hubblai/hubbl-core/models/Chat.js';
import { UIComponent } from './components/UI/types';

export const renderTextWithTags = (text: string, settings: UISettings, supportsMarkdown: boolean) => {
  const tags = parseTagsFromString(text);
  const content = renderTags(text, tags, (tag: MessageTag, i: number) => {
    if (supportsMarkdown) {
      return getMarkdownTag(tag);
    }
    return <TagLink tag={tag} key={i} settings={settings} />;
  });

  if (settings.delay_tag_render) {
    const lastIndex = content.length - 1;
    const lastContentChunk = content[lastIndex];
    if (lastContentChunk && typeof lastContentChunk === 'string') {
      const match = findIncompleteTag(lastContentChunk);
      if (match) {
        content[lastIndex] = replaceWith(lastContentChunk, match.markdownValue, match.index, match.index + match.value.length);
      }
    }
  }
  return content;
}

// const UI_REGEX_INCOMPLETE = /<ui>(?![\s\S]*?<\/ui>)/gs;
const UI_REGEX = /\[\[ui\]\](?<content>.*?)\[\[\/ui\]\]/gs;

const parseIncompleteUIComponentsFromText = (text: string): string | undefined => {
  const match = text.indexOf('[[ui]]');
  if (match > -1) {
    return text.substring(0, match);
  }
  return undefined;
}

type UIComponentsInText = {
  components?: UIComponent[],
  from?: number,
  to?: number,
}

const parseUIComponentsFromText = (text: string): UIComponentsInText[] => {
  const components: UIComponentsInText[] = [];
  for (const match of text.matchAll(UI_REGEX)) {
    if (match.groups && match.groups.content) {
      const content = match.groups.content;
      const startIndex = match.index || 0;
      const endIndex = startIndex + match[0].length;

      try {
        let groupComponents = JSON.parse(content);
        if (!Array.isArray(groupComponents)) {
          groupComponents = [groupComponents];
        }
        // Might wanna validate here that its valid to render
        components.push({
          from: startIndex,
          to: endIndex,
          components: groupComponents as UIComponent[],
        })
      }
      catch (err) {
        console.error('Error rendering UI', content);
        // This makes sure in case there is some invalid JSON in content, we strip out the [[ui]][[/ui]] from the message
        components.push({
          from: startIndex,
          to: endIndex,
        })
      }
    }
  }
  return components;
}

export const renderTextWithoutUIComponents = (text: string): { components?: UIComponent[], content: string, hasIncompleteUI: boolean } => {
  let hasIncompleteUI = false;
  let content = text.toString()
  const components: UIComponent[] = [];
  const uiComponents = parseUIComponentsFromText(text);

  // As I remove strings from content, the indexes where components were found are changing. Need to keep track of the characters I remove
  let indexOffset = 0;
  for (const uiComponent of uiComponents) {
    if (uiComponent.components) {
      components.push(...uiComponent.components);
    }
    if (uiComponent.from && uiComponent.to) {
      content = replaceWith(content, '', uiComponent.from + indexOffset, uiComponent.to + indexOffset);
      indexOffset -= uiComponent.to - uiComponent.from;
    }
  }

  // At this point all correct pairs have been removed, so just remove everything past first [[ui]]
  const clearedContent = parseIncompleteUIComponentsFromText(content);
  if (clearedContent) {
    content = clearedContent;
    hasIncompleteUI = true;
  }

  return {
    components,
    content,
    hasIncompleteUI,
  }
}
