import { Message } from "~/store/models";
import { renderTextWithTags } from "../../utils";
import { UISettings } from "@hubblai/hubbl-core/models/Chat.js";

type Props = {
  message: Message,
  settings: UISettings,
}

const MessageTextUser: React.FC<Props> = ({ message, settings }) => {
  const text = message.getContent<string>();
  const content: React.ReactNode[] = settings.render_tags ? renderTextWithTags(message.content, settings, false) : [text];
  return (
    <div className="hbl-message-text-content">
      {content}
    </div>
  )
}

export default MessageTextUser;
