import { BaseMessage, MESSAGE_CONTENT_TYPE, MESSAGE_STATUS } from "@hubblai/hubbl-core/models/Message.js";
import { BaseAgent } from "@hubblai/hubbl-core/models/Agent.js";
import { MESSAGE_SOURCE } from "@hubblai/hubbl-core/models/Message.js";
import { BaseOrganization } from "@hubblai/hubbl-core/models/Organization.js";
import { BaseModel, ModelFactory, User, Organization } from "@hubblai/hubbl-ui/store/models.js";
import { isEmpty } from "@hubblai/hubbl-core/lib/string.js";
import { UISettings } from "@hubblai/hubbl-core/models/Chat.js";
import { renderTextWithoutUIComponents } from "~/components/Chat/utils";
import { UIComponent } from "~/components/Chat/components/UI/types";

export class Agent extends BaseAgent {

  organization?: BaseOrganization;

  static fromDTO(data: any): Agent {
    return ModelFactory.create<Agent>(Agent, {
      id: data.id,
      model_id: data.model_id,
      display_name: data.display_name,
      icon: data.icon,
      description: data.description,
      created_at: data.created_at,
      created_by: data.created_by,
      organization: data.organization ? Organization.fromDTO(data.organization) : undefined,
    });
  }

  static fromDTOs(data: any): Agent[] {
    return data.map((item: any) => Agent.fromDTO(item));
  }
}

export class Chat extends BaseModel {
  id = '';
  icon = '';
  title = '';
  created_at = 0;
  last_message_at = 0;
  last_message_by = '';
  last_message = '';
  last_seen_at = 0;
  settings?: UISettings;

  internal_config: any;
  config: any;

  users: User[] = [];
  agents: Agent[] = [];

  isUnread() {
    return this.last_seen_at < this.last_message_at;
  }

  static fromDTOs(data: any): Chat[] {
    return data.map((item: any) => Chat.fromDTO(item));
  }

  static fromDTO(data: any): Chat {
    return ModelFactory.create<Chat>(Chat, {
      id: data.id,
      title: data.title,
      icon: data.icon,
      created_at: data.created_at,
      last_message_by: data.last_message_by,
      last_message_at: data.last_message_at,
      last_message: data.last_message,
      last_seen_at: data.last_seen_at,
      internal_config: data.internal_config,
      config: data.config,
      users: data.users ? User.fromDTOs(data.users) : [],
      agents: data.agents ? Agent.fromDTOs(data.agents) : [],
      settings: data.settings,
    });
  }

}

export class Message extends BaseMessage {

  _components: UIComponent[] = [];
  _hasIncompleteUIComponent: boolean = false;

  getGroupName(): string {
    if (this.source === MESSAGE_SOURCE.AGENT || this.source === MESSAGE_SOURCE.USER) {
      const author = this.getAuthor();
      return author?.display_name || '';
    }
    return '';
  }

  getGroupId(): string {
    const author = this.getAuthor();
    if (author) {
      return author.id;
    }
    return `${this.source}`;
  }

  isSubmitting() {
    return this.status === MESSAGE_STATUS.SUBMITTING;
  }

  hasContent() {
    return !isEmpty(this.content);
  }

  isContentValid() {
    if (this.content_type === MESSAGE_CONTENT_TYPE.JSON) {
      return typeof this.content === 'object';
    }
    return typeof this.content === 'string';
  }

  canMarkdown() {
    return this.isByAgent();
  }

  setContent(content?: string, shouldParseUI: boolean = false) {
    this.content = content || '';

    if (content && shouldParseUI && this.canContainUI()) {
      const result = renderTextWithoutUIComponents(content);
      this.content = result.content;
      this._components = result.components || [];
      this._hasIncompleteUIComponent = result.hasIncompleteUI;
    }
    this._content = undefined;
  }

  canContainUI() {
    return this.isByAgent() && this.isText() && this.hasContent();
  }

  appendContent(content?: string) {
    this.setContent((this.content || '') + content);
  }

  getHasIncompleteUIComponent() {
    return this._hasIncompleteUIComponent;
  }

  getUIComponents() {
    return this._components;
  }

  static fromDTOs(data: any): Message[] {
    return data.map((item: any) => Message.fromDTO(item));
  }

  static fromDTO(data: any): Message {
    const message = ModelFactory.create<Message>(Message, {
      id: data.id,
      created_at: data.created_at,
      user_id: data.user_id,
      agent_id: data.agent_id,
      status: data.status,
      source: data.source,
      content_type: data.content_type,
      surface: data.surface,
    });
    message.setContent(data.content);

    if (data.author) {
      message.author = {
        id: data.author.id,
        type: data.author.type,
        display_name: data.author.display_name,
        icon: data.author.icon,
      }
    }
    if (data.metadata) {
      const tokens = data.metadata.tokens;
      if (tokens) {
        message.metadata = {
          tokens: {
            input: tokens.input || 0,
            input_cost: tokens.input_cost || 0,
            output: tokens.output || 0,
            output_cost: tokens.output_cost || 0,
          }
        }
      }
    }
    return message;
  }
}
