import { ComponentProps } from "@hubblai/hubbl-ui/components/types.js";
import { MessageButton, MessageGroupType } from "../types";
import MessageGroup from "./MessageGroup";
import { useEffect, useRef, useState } from "react";
import { Spinner } from "@hubblai/hubbl-ui/components/index.js";
import clsx from "clsx";
import { UISettings } from "@hubblai/hubbl-core/models/Chat.js";
import { UIComponent } from "./UI/types";

type Props = {
  groups: MessageGroupType[],
  settings: UISettings,
  messageButtons?: MessageButton[],
  isFetching: boolean,
  onUserScrollToTop: () => void,
  formHeight: number,
  onComponentActivated: (messageId: string, component: UIComponent, text?: string, value?: string) => void,
} & ComponentProps;

const SCROLL_TOP_CLOSE_TO_BOTTOM_OFFSET = 5;
const SCROLL_TRIGGER_FETCH_OFFSET = 50;

const MessageList: React.FC<Props> = ({ groups, settings, onUserScrollToTop, messageButtons, isFetching, formHeight, onComponentActivated }) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [hasUserScrolled, setHasUserScrolled] = useState(false);

  const scrollToBottom = (instant: boolean = false) => {
    if (rootRef && rootRef.current) {
      messagesEndRef?.current?.scrollIntoView({ behavior: instant ? "instant" : "smooth" });
    }
  }

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target as HTMLDivElement;
    const hasUserScrolledNow = scrollTop + clientHeight + SCROLL_TOP_CLOSE_TO_BOTTOM_OFFSET < scrollHeight;
    if (hasUserScrolledNow !== hasUserScrolled) {
      setHasUserScrolled(hasUserScrolledNow);
    }
    if (scrollTop < SCROLL_TRIGGER_FETCH_OFFSET) {
      onUserScrollToTop();
    }
  }

  useEffect(() => {
    if (!hasUserScrolled) {
      // When form size gets changed, it makes sure scroll stays at the bottom
      scrollToBottom(true);
    }
  }, [formHeight, hasUserScrolled]);

  useEffect(() => {
    if (groups && !hasUserScrolled) {
      // On new message scroll down
      scrollToBottom(true);
    }
  }, [groups, hasUserScrolled]);

  // TODO:P1 scrolllock
  // TODO:p1 message cancel
  // TODO:P1 message reply
  // tODO:P1 general messagebuttons -> message group buttons
  // TODO:p1 message buttons

  return (
    <div ref={rootRef} className={clsx("hbl-message-list", { "hbl-message-list-dual": settings.separate_message_sides })} onScroll={onScroll}>
      {isFetching && <Spinner />}
      {groups.map((group, index) =>
        <MessageGroup
          key={group.id}
          group={group}
          settings={settings}
          messageButtons={messageButtons}
          onComponentActivated={onComponentActivated}
          isLastGroup={index === groups.length - 1}
        />
      )}
      <div ref={messagesEndRef} />
    </div>
  )
}

export default MessageList;
