import { nowUTS } from "@hubblai/hubbl-core/lib/clock.js";
import { isEmpty, uuid } from "@hubblai/hubbl-core/lib/string.js";
import { TagEntityType } from "@hubblai/hubbl-core/lib/tags.js";
import { UISettings, DEFAULT_SETTINGS } from "@hubblai/hubbl-core/models/Chat.js";
import { MESSAGE_SOURCE, MESSAGE_STATUS } from "@hubblai/hubbl-core/models/Message.js";
import { Spinner } from "@hubblai/hubbl-ui/components/index.js";
import API from "@hubblai/hubbl-ui/lib/api.js";
import { getSessionInfo } from "@hubblai/hubbl-ui/lib/auth.js";
import { User } from "@hubblai/hubbl-ui/store/models.js";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ChatComponent from '~/components/Chat';
import { UIComponent } from "~/components/Chat/components/UI/types";
import { APIStream } from "~/lib/stream";
import { Message, Chat, Agent } from "~/store/models";
// import { useAppDispatch } from '@hubblai/hubbl-ui/store/index.js';
// import { updateMessageContent } from "~/store/messages/actions";

const createTempMessage = (user: User, content: string) => {
  const message = new Message();
  message.id = uuid();
  message.user_id = user.id;
  message.setContent(content);
  message.created_at = nowUTS();
  message.status = MESSAGE_STATUS.SUBMITTING;
  message.source = MESSAGE_SOURCE.USER;
  message.author = {
    id: user.id,
    icon: '',
    type: TagEntityType.USER,
    display_name: user.getDisplayName(),
  }
  return message;
}

const loadCSS = (cssName: string) => {
  const filename = `/themes/${cssName}.css`;
  const existingLink = document.querySelector(`link[href="${filename}"]`);
  if (!existingLink) {
    const link = document.createElement("link");
    link.href = filename;
    link.type = "text/css";
    link.rel = "stylesheet";
    document.head.appendChild(link);
  }
}

const ChatPage: React.FC = () => {
  const params = useParams();
  const chatId = params.chatId!;

  const hasFetchedChat = useRef(false);
  // const dispatch = useAppDispatch();

  const stringBuffer = useRef<string>('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [isFetchingChat, setIsFetchingChat] = useState(false);
  const [isFetchingMessages, setIsFetchingMessages] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [settings, setSettings] = useState<UISettings>({...DEFAULT_SETTINGS});

  const currentUserId = useMemo(() => getSessionInfo()!.user_id, []);

  const user = users.find(u => u.id === currentUserId);

  const addMessage = useCallback((message: Message) => {
    setMessages(currentMessages => {
      const index = currentMessages.findIndex(m => m.id === message.id);
      if (index === -1) {
        const newMessages = [...currentMessages];
        newMessages.push(message);
        return newMessages;
      }
      return currentMessages;
    });
  }, []);

  const updateMessage = useCallback((id: string, message: Message) => {
    setMessages(currentMessages => {
      const newMessages = [...currentMessages];
      const index = newMessages.findIndex(m => m.id === id);
      if (index > -1) {
        newMessages[index] = message;
      }
      return newMessages;
    });
  }, []);

  const updateMessageContent = useCallback((messageId: string, content: string) => {
    stringBuffer.current += content;
    const nextContentUpdate = content;

    if (nextContentUpdate.length > 0) {
      setMessages(currentMessages => {
        const newMessages = [...currentMessages];
        const index = newMessages.findIndex(m => m.id === messageId);
        if (index > -1) {
          newMessages[index].appendContent(content);
        }
        return newMessages;
      });
    }
  }, []);

  const startMessageStream = useCallback((chatId: string, message: Message) => {
    const url = `/chats/${chatId}/messages/${message.id}/content`;
    const messageStream = APIStream.for(url);
    if (messageStream) {
      messageStream.on('chunk', (content: string) => {
        // dispatch(updateMessageContent(message.id, content));
        updateMessageContent(message.id, content);
      });
      messageStream.connect();
    }
  }, [updateMessageContent]);
  // }, [dispatch]);

  const subscribeToChat = useCallback((chatId: string, currentUserId: string) => {
    API.subscribe(`/chats/${chatId}`, '/messages', data => {
      const { method, payload } = data;
      const message = Message.fromDTO(payload);

      if (method === "POST") {
        if (message.isStreaming()) {
          startMessageStream(chatId, message);
        }
        if (!message.isByUser(currentUserId)) {
          addMessage(message);
        }
      } else if (method === "PATCH") {
        updateMessage(message.id, message);
      }
    });
  }, [startMessageStream, addMessage, updateMessage]);

  const fetchMessages = useCallback(async () => {
    setIsFetchingMessages(true);
    try {
      // TODO:P1 pagination
      const { data } = await API.get(`/chats/${chatId}/messages`);
      const messages = Message.fromDTOs(data);
      setMessages(messages);
      for (const message of messages) {
        if (message.isStreaming()) {
          startMessageStream(chatId, message);
        }
      }
    }
    catch (err) {
      console.error(err);
    }
    setIsFetchingMessages(false);
  }, [chatId, startMessageStream]);

  const fetchChat = useCallback(async () => {
    setIsFetchingChat(true);
    try {
      const { data } = await API.get(`/chats/${chatId}`);
      const chat = Chat.fromDTO(data);
      setUsers(chat.users);
      setAgents(chat.agents);
      subscribeToChat(chat.id, currentUserId);
      setSettings(currentSettings => ({
        ...currentSettings,
        ...(chat.settings || {}),
      }));
      hasFetchedChat.current = true;
    }
    catch (err) {
      console.error(err);
    }
    setIsFetchingChat(false);
  }, [chatId, currentUserId, subscribeToChat]);

  useEffect(() => {
    fetchMessages();
    fetchChat();
  }, [fetchMessages, fetchChat]);

  useEffect(() => {
    if (hasFetchedChat.current) {
      loadCSS(settings.theme);
    }
  }, [settings])

  const onUserScrollToTop = () => {
    // TODO:P1 if not fetching and has more pages, get more messages
  }

  const onSubmitMessage = useCallback(async (content: string) => {
    const tempMessage = createTempMessage(user!, content);
    addMessage(tempMessage);
    setIsSubmitting(true);
    try {
      const { data } = await API.post(`/chats/${chatId}/messages`, { content });
      const message = Message.fromDTO(data);
      updateMessage(tempMessage.id, message);
    }
    catch (err) {
      tempMessage.status = MESSAGE_STATUS.FAILED_TO_SUBMIT;
      updateMessage(tempMessage.id, tempMessage);
    }
    setIsSubmitting(false);
  }, [addMessage, updateMessage, user, chatId]);

  const onComponentActivated = useCallback((messageId: string, _component: UIComponent, text?: string, value?: string) => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage?.id !== messageId) {
      // Actions are considered relevant only for the latest message
      return;
    }

    if (!isEmpty(text)) {
      onSubmitMessage(text!);
    }

    if (!isEmpty(value)) {
      // Thiscould send a system message or something that is not visible to the user but is visible to the agent
    }

  }, [messages, onSubmitMessage]);

  return (
    <>
      {isFetchingChat && <Spinner />}
      {!isFetchingChat &&
        <ChatComponent
          settings={settings}
          messages={messages}
          users={users}
          agents={agents}
          isFetching={isFetchingMessages}
          currentUserId={currentUserId}
          onUserScrollToTop={onUserScrollToTop}
          onSubmitMessage={onSubmitMessage}
          isSubmitting={isSubmitting}
          onComponentActivated={onComponentActivated}
        />
      }
    </>
  )
}

export default ChatPage;
