import { Message } from "~/store/models";

type Props = {
  message: Message,
}

type MessageFunctionCallContent = {
  icon: string,
  title: string,
  functionName: string,
}

const MessageFunctionCall: React.FC<Props> = ({ message }) => {
  const content = message.getContent<MessageFunctionCallContent>();
  return (
    <div className="hbl-message-function-call">
      <img src={content.icon} />
      <span>Executing {content.title}: {content.functionName}...</span>
    </div>
  )
}

export default MessageFunctionCall;
