const defaultConfig = {
  FETCH_MESSAGES_DEFAULT_LIMIT: 20,
  SCROLL_TRIGGER_FETCH_OFFSET: 50,
  API_BASE_URL: "https://api.gethubbl.xyz",
  SENTRY: {
    DSN: "https://3b46d786a546292248f5a8de33113952@o4507162264403968.ingest.us.sentry.io/4507284922826752",
  },
  PUSHER: {
    APP_KEY: "b2f3423170e9989aefe5",
    CLUSTER: "us3",
  }
};

const envConfig = {
  local: {
    API_BASE_URL: 'http://localhost:8080',
  },
  dev: {
    API_BASE_URL: 'https://api.gethubbl.xyz',
  },
  prod: {

  }
}

const env = process.env.NODE_ENV || 'local';

export const config = {
  ...defaultConfig,
  ...(envConfig as any)[env],
};

export default config;
