import { ComponentProps } from "@hubblai/hubbl-ui/components/types.js";
import { Link } from "react-router-dom";
import { MessageTag, getDisplayTag } from '@hubblai/hubbl-core/lib/tags.js';
import { UISettings } from "@hubblai/hubbl-core/models/Chat.js";

type Props = {
  tag: MessageTag,
  settings: UISettings,
} & ComponentProps;

const getLinkFromTemplate = (template: string, _tag: MessageTag) => {
  // TODO:P1 might actually apply the template like /users/:userId... but might wanna just do it bsaed on tag so object is better as user might have differnt than agent
  return template;
}

const TagLink: React.FC<Props> = ({ tag, settings }) => {
  const text = getDisplayTag(tag);
  if (settings.tag_link_template) {
    const link = getLinkFromTemplate(settings.tag_link_template, tag);
    return <Link className="hbl-message-tag" to={link}>{text}</Link>;
  }
  return <a className="hbl-message-tag">{text}</a>
}

export default TagLink;
