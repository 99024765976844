import App from './App';

import ChatPage from './pages/chat';
import Home from './pages/home';

export default [
  {
    element: <App />,
    children: [
      {
        path: '/chats/:chatId',
        element: <ChatPage />,
      },
      {
        path: '/',
        element: <Home />
      },
    ]
  },
];
