import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import createStore from '@hubblai/hubbl-ui/store/index.js';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import routes from './routes';
import { init, browserTracingIntegration, replayIntegration } from "@sentry/react";

import './index.css';

import "primereact/resources/themes/md-light-indigo/theme.css";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import reducers from '~/store/reducers';

import config from './config';

const store = createStore(reducers);

if (process.env.NODE_ENV !== 'local') {
  init({
    dsn: config.SENTRY.DSN,
    integrations: [
      browserTracingIntegration(),
      replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/api.gethubbl.ai/, /^https:\/\/api.gethubbl.xyz/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.createRoot(document.getElementById('app') as HTMLElement).render(
  <Provider store={store}>
    <RouterProvider router={createBrowserRouter(routes)} />
  </Provider>
);
