import EventEmitter from 'eventemitter3';
import API from '@hubblai/hubbl-ui/lib/api.js';

const streams: { [url: string]: APIStream } = {};

export class APIStream {

  isConnected: boolean = false;
  emitter = new EventEmitter();
  url: string;
  controller: AbortController | undefined;

  constructor(url: string) {
    this.url = url;
  }

  static for(url: string, unique: boolean = true) {
    if (unique && streams[url]) {
      return undefined;
    }
    if (!streams[url]) {
      streams[url] = new APIStream(url);
    }
    return streams[url];
  }

  static disconnect(url: string) {
    if (streams[url]) {
      streams[url].disconnect();
    }
  }

  disconnect() {
    if (this.controller) {
      this.controller.abort();
    }
    delete streams[this.url];
    this.emitter.removeAllListeners();
    this.isConnected = false;
  }

  connect() {
    if (!this.isConnected) {
      this.isConnected = true;
      API.stream(this.url,
        (chunk: string) => {
          this.emitter.emit('chunk', chunk.toString());
        },
        (content: string) => {
          this.emitter.emit('completed', content);
          this.disconnect();
        },
        (controller => {
          this.controller = controller;
        })
      );
    }
  }

  on(eventName: string, callback: any) {
    this.emitter.on(eventName, callback);
  }

  emit(eventName: string, payload?: any) {
    this.emitter.emit(eventName, payload);
  }
}
