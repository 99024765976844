import { ComponentProps } from "@hubblai/hubbl-ui/components/types.js";
import { MessageButton, MessageGroupType } from "../types";
import Message from "./Message";
import MessageGroupHeader from "./MessageGroupHeader";
import clsx from "clsx";
import { UISettings } from "@hubblai/hubbl-core/models/Chat.js";
import { UIComponent } from "./UI/types";

type Props = {
  group: MessageGroupType,
  settings: UISettings,
  messageButtons?: MessageButton[],
  onComponentActivated: (messageId: string, component: UIComponent, text?: string, value?: string) => void,
  isLastGroup: boolean
} & ComponentProps;

const MessageGroup: React.FC<Props> = ({ group, settings, messageButtons, onComponentActivated, isLastGroup }) => {
  const showHeader = (
    (group.sender === 'current' && settings.show_current_user_group_header)
    || (group.sender !== 'current' && settings.show_other_user_group_header)
  );

  return (
    <div className={clsx(`hbl-group hbl-group-${group.sender}`, { 'hbl-group-typing': group.isTyping })}>
      {showHeader && <MessageGroupHeader group={group} settings={settings} buttons={messageButtons} />}
      <div className="hbl-messages">
        {group.messages.map((message, index) =>
          <Message key={message.id} message={message} settings={settings} onComponentActivated={onComponentActivated} isLastMessage={isLastGroup && index === group.messages.length - 1} />
        )}
      </div>
    </div>
  )
}

export default MessageGroup;
