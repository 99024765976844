import { ComponentProps } from "@hubblai/hubbl-ui/components/types.js";
import { MentionsInput, Mention, OnChangeHandlerFunc } from 'react-mentions';
import { User } from "@hubblai/hubbl-ui/store/models.js";
import { Agent } from "~/store/models";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, DropdownMenu, DropdownMenuItem } from "@hubblai/hubbl-ui/components/index.js";
import { UISettings } from "@hubblai/hubbl-core/models/Chat.js";
import { TagEntityType, createAgentTag, createTagId, createUserTag } from "@hubblai/hubbl-core/lib/tags.js";

type MentionItem = {
  id: string,
  display: string,
}

type Props = {
  settings: UISettings,
  onSizeChanged: (height: number) => void,
  users: User[],
  agents: Agent[],
  isSubmitting: boolean,
  currentUserId: string,
  onClickSubmit: (value: string) => void,
} & ComponentProps;

const getDisplayMention = (_id: string, display: string) => `@${display}`;

const getGroupsAndMentions = (agents: Agent[], users: User[], onTagAgent: (agent: Agent) => void, onTagUser: (user: User) => void): { groups: DropdownMenuItem[], mentions: MentionItem[] } => {
  const groups: DropdownMenuItem[] = [];
  const mentions: MentionItem[] = []

  if (agents.length) {
    groups.push({
      label: 'AI',
      items: []
    });
  }
  if (users.length) {
    groups.push({
      label: 'Users',
      items: []
    });
  }

  agents.forEach(agent => {
    groups[0].items!.push({
      label: agent.getDisplayName(),
      onClick: () => onTagAgent(agent),
    });

    mentions.push({
      id: createTagId(TagEntityType.AGENT, agent.id),
      display: agent.getDisplayName()
    });
  });

  users.forEach(user => {
    mentions.push({
      id: createTagId(TagEntityType.USER, user.id),
      display: user.getDisplayName()
    });

    groups[groups.length - 1]!.items!.push({
      label: user.getDisplayName(),
      onClick: () => onTagUser(user),
    });
  });

  return {
    groups,
    mentions,
  }
}

const MessageForm: React.FC<Props> = ({ agents, users, currentUserId, settings, isSubmitting, onClickSubmit, onSizeChanged }) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const rootHeightRef = useRef<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isTagging, setIsTagging] = useState(false);
  const otherUsers = users.filter(user => user.id !== currentUserId);

  const [message, setMessage] = useState('');

  useEffect(() => {
    if (inputRef?.current && message && message.length > 0) {
      inputRef.current.focus();
    }
    if (rootRef?.current) {
      if (rootHeightRef.current != rootRef.current.offsetHeight) {
        rootHeightRef.current = rootRef.current.offsetHeight;
        onSizeChanged(rootHeightRef.current);
      }
    }
  }, [message, onSizeChanged]);

  useEffect(() => {
    if (rootRef.current) {
      if (rootHeightRef.current != rootRef.current.offsetHeight) {
        rootHeightRef.current = rootRef.current.offsetHeight;
        onSizeChanged(rootHeightRef.current);
      }
    }
  }, [rootRef, onSizeChanged]);

  const onClickRoot = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLElement).nodeName === "DIV" && inputRef?.current) {
      inputRef?.current.focus();
    }
  }, []);

  const onClickSendMessage = useCallback(() => {
    const cleanMessage = message.trim();
    if (!isSubmitting && cleanMessage.length > 0) {
      onClickSubmit(cleanMessage);
      setMessage('');
    }
  }, [message, isSubmitting, onClickSubmit]);

  const onKeyPress = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const keystroke = settings.message_form_send_keystroke;
    if (keystroke !== 'None' && e.key === 'Enter' && !e.shiftKey) {
      if (keystroke === 'Enter' || (keystroke === 'CtrlCmdEnter' && (e.ctrlKey || e.metaKey))) {
        e.preventDefault();
        onClickSendMessage();
      }
    }
  }, [onClickSendMessage, settings.message_form_send_keystroke])

  const onMessageChanged: OnChangeHandlerFunc = useCallback((e: any) => {
    setMessage(e.target.value);
  }, []);

  const onTagMenuHide = () => {
    setIsTagging(false);
  }

  const onClickTagMenu = () => {
    setIsTagging(!isTagging);
  }

  const onTag = (tag: string) => {
    setMessage(message => {
      return message + tag + ' ';
    });
  }

  const onTagAgent = (agent: Agent) => {
    onTag(createAgentTag(agent));
  };

  const onTagUser = (user: User) => {
    onTag(createUserTag(user));
  };

  const { groups, mentions } = getGroupsAndMentions(agents, otherUsers, onTagAgent, onTagUser);
  const canTag = agents.length + otherUsers.length > 0;

  return (
    <div className="hbl-message-form" ref={rootRef} onClick={onClickRoot}>
      {canTag && <DropdownMenu icon='at' buttonClassName="hbl-mentions__button" variant={isTagging ? 'primary' : 'link'} items={groups} onToggle={onClickTagMenu} onHide={onTagMenuHide} />}
      <MentionsInput
        inputRef={inputRef}
        value={message}
        onChange={onMessageChanged}
        onKeyPress={onKeyPress}
        placeholder={settings.message_form_placeholder}
        className={"hbl-mentions"}
        forceSuggestionsAboveCursor>
        <Mention
          className={"hbl-mentions__mention"}
          trigger="@"
          data={mentions}
          markup={"@[__display__](__id__)"}
          displayTransform={getDisplayMention}
          appendSpaceOnAdd
        />
      </MentionsInput >
      <Button onClick={onClickSendMessage} title={settings.message_form_button_title} icon={settings.message_form_button_icon} isLoading={isSubmitting} className={"hbl-message-form-button"} />
    </div>
  )
}

export default MessageForm;
