import { Message } from "~/store/models";

type Props = {
  message: Message,
}

type MessageIFrameContent = {
  width: number,
  height: number,
  url: string,
  title: string,
}

const MessageIFrame: React.FC<Props> = ({ message }) => {
  const content = message.getContent<MessageIFrameContent>();
  return (
    <iframe width={content.width} height={content.height} src={content.url} title={content.title} />
  )
}
export default MessageIFrame;
