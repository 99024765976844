import { ComponentProps } from "@hubblai/hubbl-ui/components/types.js";
import { UIComponentRichListItem } from "./types";
import { Message } from "~/store/models";

type Props = {
  message: Message,
  component: UIComponentRichListItem
  onActivated: (messageId: string, component: UIComponentRichListItem, text: string, value: string) => void,
} & ComponentProps

const MessageButton: React.FC<Props> = ({ component, onActivated, message }) => {
  return (
    <div className="hbl-message-ui-item hbl-message-ui-richlistitem" onClick={() => onActivated(message.id, component, `${component.title} ${component.subtitle}`, component.id)}>
      {component.icon && <img className="hbl-message-ui-richlistitem-image" src={component.icon} />}
      <div className="hbl-message-ui-richlistitem-content">
        <div className="hbl-message-ui-richlistitem-title">{component.title}</div>
        <div className="hbl-message-ui-richlistitem-subtitle">{component.subtitle}</div>
        <div className="hbl-message-ui-richlistitem-description">{component.description}</div>
      </div>
    </div>
  )
}

export default MessageButton;
