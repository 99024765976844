import { ComponentProps } from "@hubblai/hubbl-ui/components/types.js";
import { UIComponent, UIComponentIFrame } from "./types";
import { Message } from "~/store/models";
import { useRef } from "react";

type Props = {
  message: Message,
  component: UIComponentIFrame,
  onActivated: (messageId: string, component: UIComponent, text: string) => void,
} & ComponentProps

const MessageIFrame: React.FC<Props> = ({ component, onActivated, message }) => {
  // TODO:P1 this logic sucks for multiple submissions
  const wasProcessed = useRef<boolean>(false);
  window.addEventListener('message', (e) => {
    if (e.data.type === "hbl-form-submit" && !wasProcessed.current) {
      wasProcessed.current = true;
      onActivated(message.id, component, "I submitted the form!");
    }
  }, false);

  return (
    <iframe src={component.url} width={component.width || '100%'} height={component.height || '100%'} />
  )
}

export default MessageIFrame;
