import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { Message } from "~/store/models";
import { Button, Tag } from "@hubblai/hubbl-ui/components/index.js";
import { renderTextWithTags, renderTextWithoutUIComponents } from '../../utils';
import { UISettings } from '@hubblai/hubbl-core/models/Chat.js';
import MessageUI from '../UI';
import { UIComponent } from '../UI/types';
// import { useMessageContent } from '~/store/messages/hooks';
// import { MESSAGE_STATUS } from '@hubblai/hubbl-core/models/Message.js';

type Props = {
  message: Message,
  settings: UISettings,
  onComponentActivated: (messageId: string, component: UIComponent, text?: string, value?: string) => void,
  isLastMessage: boolean,
}

const MessageTextAgent: React.FC<Props> = ({ message, settings, onComponentActivated, isLastMessage }) => {
  const wasCancelled = message.isCancelled();
  // const streamedContent = useMessageContent(message.id);
  let text = message.getContent<string>();
  let uiComponents = message.getUIComponents();
  let hasIncompleteUI = message.getHasIncompleteUIComponent();
  const canShowUI = isLastMessage;

  const parseDataOnRender = true;
  if (parseDataOnRender) {
    const result = renderTextWithoutUIComponents(text);
    text = result.content;
    uiComponents = result.components || [];
    hasIncompleteUI = result.hasIncompleteUI;
  }

  const content: React.ReactNode[] = settings.render_tags ? renderTextWithTags(text, settings, true) : [text];

  return (
    <>
      <div className="hbl-message-text-content">
        <Markdown
          className={"hbl-markdown"}
          remarkPlugins={[remarkGfm]}
          children={content.join('').toString()}
          components={{
            pre(pre: any) {
              const codeChunk = pre.node?.children[0]?.children[0]?.value as string;
              const className = pre.node?.children[0]?.properties?.className;
              if (className) {
                const language = className[0].replace(/language-/g, "") as string;
                return (
                  <div className={"hbl-code"}>
                    <div className={"hbl-code-header"}>
                      <Tag title={language} className={"hbl-code-tag"} />
                      <CopyToClipboard text={codeChunk}>
                        <Button icon="copy" size="icon" tooltip='Copy' className={"hbl-code-button"} />
                      </CopyToClipboard>
                    </div>
                    <pre {...pre}></pre>
                  </div>
                );
              }
              return <pre {...pre}></pre>;
            },
            code(props) {
              const { children, className, ...rest } = props
              const match = /language-(\w+)/.exec(className || '')
              return match ? (
                <SyntaxHighlighter
                  PreTag="div"
                  className={"hbl-highlighter"}
                  children={String(children).replace(/\n$/, '')}
                  language={match[1]}
                  style={oneDark}
                  showLineNumbers
                />
              ) : (
                <code {...rest} className={className}>
                  {children}
                </code>
              )
            }
          }}
        />
        {wasCancelled && settings.show_interrupted_info && <span className='italic text-sm opacity-50'>(interrupted)</span>}
      </div>
      {canShowUI && (uiComponents.length > 0 || hasIncompleteUI) && <MessageUI components={uiComponents} message={message} onActivated={onComponentActivated} hasIncompleteUI={hasIncompleteUI} />}
    </>
  )
}

export default MessageTextAgent;
