import { ComponentProps } from "@hubblai/hubbl-ui/components/types.js";
import { Message } from "~/store/models";
import { MESSAGE_SURFACE } from "@hubblai/hubbl-core/models/Message.js";
import MessageTextUser from "./MessageTextUser";
import MessageTextAgent from "./MessageTextAgent";
import MessageImage from "./MessageImage";
import MessageIFrame from "./MessageIFrame";
import MessageFunctionCall from "./MessageFunctionCall";
import clsx from "clsx";
import MessageSystem from "./MessageSystem";
import { UISettings } from "@hubblai/hubbl-core/models/Chat.js";
import { UIComponent } from "../UI/types";

type Props = {
  message: Message,
  settings: UISettings,
  onComponentActivated: (messageId: string, component: UIComponent, text?: string, value?: string) => void,
  isLastMessage: boolean,
} & ComponentProps;

const messageSurfaceToClassMap = {
  [MESSAGE_SURFACE.FUNCTION_CALL]: 'function-call',
  [MESSAGE_SURFACE.IFRAME]: 'iframe',
  [MESSAGE_SURFACE.IMAGE]: 'image',
  [MESSAGE_SURFACE.TEXT]: 'text',
  [MESSAGE_SURFACE.VIDEO]: 'video',
}

const getMessageClass = (message: Message) => {
  if (message.isSystem()) {
    return 'system';
  }
  if (message.isSubmitting()) {
    return 'submitting';
  }
  return messageSurfaceToClassMap[message.surface] || 'unknown';
}

const MessageComponent: React.FC<Props> = ({ message, isLastMessage, settings, onComponentActivated }) => {
  return (
    <div className={clsx("hbl-message", `hbl-message-${getMessageClass(message)}`, { "hbl-message-error": message.isError() })}>
      {message.hasContent() && message.isContentValid() &&
        <>
          {message.surface === MESSAGE_SURFACE.TEXT && message.isByAgent() && <MessageTextAgent message={message} settings={settings} onComponentActivated={onComponentActivated} isLastMessage={isLastMessage} />}
          {message.surface === MESSAGE_SURFACE.TEXT && message.isByUser() && <MessageTextUser message={message} settings={settings} />}
          {message.surface === MESSAGE_SURFACE.TEXT && message.isSystem() && settings.display_system_messages && <MessageSystem message={message} />}
          {message.surface === MESSAGE_SURFACE.IFRAME && <MessageIFrame message={message} />}
          {message.surface === MESSAGE_SURFACE.IMAGE && <MessageImage message={message} />}
          {message.surface === MESSAGE_SURFACE.FUNCTION_CALL && settings.display_function_calls && <MessageFunctionCall message={message} />}
        </>
      }
    </div>
  )
}

export default MessageComponent;
