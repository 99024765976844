import { ComponentProps } from "@hubblai/hubbl-ui/components/types.js";
import { UIComponentSelect } from "./types";
import { Message } from "~/store/models";
import { Select, SelectChangeEvent } from "@hubblai/hubbl-ui/components/index.js";
import { isNil } from "@hubblai/hubbl-core/lib/object.js";

type Props = {
  message: Message,
  component: UIComponentSelect,
  onActivated: (messageId: string, component: UIComponentSelect, text: string, value: string) => void,
} & ComponentProps

const MessageSelect: React.FC<Props> = ({ component, message, onActivated }) => {

  const onChange = (e: SelectChangeEvent) => {
    // Might need to do some fixes to send value vs text separately
    onActivated(message.id, component, e.target.value, e.target.value);
  }

  const firstItem = component.options[0];
  const itemFieldValue = isNil(firstItem.value) ? "text" : "value";

  return (
    <Select className="hbl-message-ui-item hbl-message-ui-select" placeholder={component.placeholder} items={component.options} onChange={onChange} itemFieldLabel="text" itemFieldValue={itemFieldValue} value={null} />
  )
}

export default MessageSelect;
