import { ComponentProps } from "@hubblai/hubbl-ui/components/types.js";
import { UIComponent, isButton, isRichListItem, isSelect, isIFrame } from "./types";
import MessageButton from "./button";
import MessageSelect from "./select";
import MessageRichListItem from "./richlistitem";
import { Message } from "~/store/models";
import { Spinner } from "@hubblai/hubbl-ui/components/index.js";
import MessageIFrame from "./iframe";

type Props = {
  message: Message,
  components: UIComponent[],
  onActivated: (messageId: string, component: UIComponent, text?: string, value?: string) => void,
  hasIncompleteUI: boolean,
} & ComponentProps

const MessageUI: React.FC<Props> = ({ components, message, onActivated, hasIncompleteUI }) => {
  return (
    <div className="hbl-message-ui">
      {components.map((component: UIComponent, i: number) => {
        if (isButton(component)) {
          return <MessageButton key={i} component={component} message={message} onActivated={onActivated} />;
        } else if (isSelect(component)) {
          return <MessageSelect key={i} component={component} message={message} onActivated={onActivated} />;
        } else if (isRichListItem(component)) {
          return <MessageRichListItem key={i} component={component} message={message} onActivated={onActivated} />;
        } else if (isIFrame(component)) {
          return <MessageIFrame key={i} component={component} message={message} onActivated={onActivated} />;
        }
        return undefined;
      })}
      {hasIncompleteUI && <Spinner />}
    </div>
  )
}

export default MessageUI;
