import { isEmpty } from "@hubblai/hubbl-core/lib/string.js";
import { Spinner } from "@hubblai/hubbl-ui/components/index.js";
import { persistSessionInfo } from "@hubblai/hubbl-ui/lib/auth.js";
import API, { getDisplayError } from "@hubblai/hubbl-ui/lib/api.js";
import { useCallback, useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

const Home = () => {
  const [searchParams] = useSearchParams();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [chatId, setChatId] = useState('');
  const [error, setError] = useState('');

  const appid = searchParams.get('appid');
  const uid = searchParams.get('uid');
  const lang = searchParams.get('lang');

  const initialize = useCallback(async () => {
    try {
      const { data } = await API.post('/embed', { appid, lang, uid });
      persistSessionInfo(data.session)
      setIsAuthorized(true)
      setChatId(data.chat_id)
    }
    catch (err) {
      const errorMessage = getDisplayError(err);
      setError(errorMessage);
    }
  }, [appid, setError, lang, uid]);

  useEffect(() => {
    if (appid) {
      initialize();
    }
  }, [appid, initialize])

  if (!appid) {
    return (
      <div>Invalid App ID</div>
    )
  }

  if (!isEmpty(error)) {
    return <div>Error: {error}</div>;
  }

  if (!isAuthorized) {
    return <Spinner />
  }

  return (
    <Navigate to={`/chats/${chatId}`} replace />
  )
}

export default Home;
