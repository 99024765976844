import { ComponentProps } from "@hubblai/hubbl-ui/components/types.js";
import { UIComponent, UIComponentButton } from "./types";
import { Button } from "@hubblai/hubbl-ui/components/index.js";
import { Message } from "~/store/models";

type Props = {
  message: Message,
  component: UIComponentButton,
  onActivated: (messageId: string, component: UIComponent, text: string) => void,
} & ComponentProps

const MessageButton: React.FC<Props> = ({ component, onActivated, message }) => {
  return (
    <Button className="hbl-message-ui-item hbl-message-ui-button" title={component.text} onClick={() => onActivated(message.id, component, component.text)} />
  )
}

export default MessageButton;
